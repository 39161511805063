// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-fastback-studios-js": () => import("./../../../src/pages/fastback-studios.js" /* webpackChunkName: "component---src-pages-fastback-studios-js" */),
  "component---src-pages-gaido-health-js": () => import("./../../../src/pages/gaido-health.js" /* webpackChunkName: "component---src-pages-gaido-health-js" */),
  "component---src-pages-gsa-online-portal-js": () => import("./../../../src/pages/gsa-online-portal.js" /* webpackChunkName: "component---src-pages-gsa-online-portal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-ui-exercise-js": () => import("./../../../src/pages/ui-exercise.js" /* webpackChunkName: "component---src-pages-ui-exercise-js" */)
}

